var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var View = function (props) {
    return (React.createElement("svg", __assign({ width: 20, height: 20, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { d: "M18.402 9.496c-1.851-3.9-4.65-5.863-8.402-5.863-3.754 0-6.55 1.963-8.402 5.865a1.178 1.178 0 000 1.006c1.851 3.9 4.65 5.863 8.402 5.863 3.754 0 6.55-1.963 8.402-5.865.15-.317.15-.684 0-1.006zM10 14.961c-3.15 0-5.457-1.598-7.084-4.961C4.543 6.637 6.85 5.04 10 5.04c3.15 0 5.457 1.597 7.084 4.96-1.625 3.363-3.932 4.96-7.084 4.96zm-.078-8.399a3.438 3.438 0 100 6.876 3.438 3.438 0 000-6.876zm0 5.625A2.186 2.186 0 017.734 10c0-1.209.979-2.188 2.188-2.188s2.187.979 2.187 2.188a2.186 2.186 0 01-2.187 2.188z", fill: "#1A1A1A" })));
};
