export const getStudyVersion = (version: string) => {
  if (!version) return;
  const semanticVersion = version.split('.');
  const [major, minor] = semanticVersion;
  return `${major}.${minor}`;
};

export const getIncrementedMinorVersion = (
  version: string,
  majorVersion: string,
) => {
  if (!version) {
    return `${majorVersion}.0`;
  }

  const semanticVersion = version.split('.');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [major, minor] = semanticVersion;
  const minorAsNumber = Number(minor);

  return `${majorVersion}.${minorAsNumber + 1}`;
};

export const getMajorVersion = (version: string) => {
  if (!version) return;
  const semanticVersion = version.split('.');
  const [major] = semanticVersion;
  return `${major}`;
};
