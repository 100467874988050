"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enUS = void 0;
var enUS = exports.enUS = {
  common: {
    errorMessage: 'An error has occurred',
    requestError: 'There was a problem submitting your request. Try again or contact us for assistance.',
    months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December'
    },
    today: 'Today',
    weekDayLong: {
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday'
    },
    weekDayShort: {
      Sunday: 'Su',
      Monday: 'Mo',
      Tuesday: 'Tu',
      Wednesday: 'We',
      Thursday: 'Th',
      Friday: 'Fr',
      Saturday: 'Sa'
    },
    dateFormats: {
      year: 'YYYY',
      yearMonth: 'YYYY-MM',
      monthDayYear: 'MM-DD-YYYY',
      yearMonthDay: 'YYYY-MM-DD'
    },
    languages: {
      English: 'English',
      French: 'French',
      German: 'German',
      Italian: 'Italian',
      Spanish: 'Spanish'
    },
    clear: 'Clear',
    yes: 'Yes',
    no: 'No',
    other: 'Other',
    preferNotAnswer: 'I prefer not to answer',
    male: 'Male',
    female: 'Female',
    nonBinary: 'Non-binary / Gender Queer',
    transgenderFemale: 'Transgender Female/Trans Woman/MtF',
    transgenderMale: 'Transgender Male/Trans Man/FtM',
    genderNonListed: 'A gender not listed here',
    intersex: 'Intersex',
    noneDescribeMe: 'None of these describe me'
  },
  components: {
    deleteButton: {
      confirmation: 'Are you sure that you want to delete this?'
    },
    address: {
      autofillError: 'Select your verified address from the drop down below. Ensure you do not use autofill as this can be incorrect compared to the address listed. Selection of your address from the drop down is a requirement to proceed with the eligibility steps.'
    },
    uploader: {
      removeHeading: 'Remove this Image?',
      removeImage: 'Remove Image',
      cancel: 'Cancel',
      numberOfFilesError: 'Files not uploaded due to number of files. Upload up to {{MaxFiles}} photos and try again.',
      uploadRules: 'Files must be under {{formattedBytes}} and in {{extensions}} format. PDF files will not be accepted.'
    },
    checkboxGroup: {
      noneAbove: 'None of the above'
    },
    connectedFile: {
      imageError: 'There was a problem with your image. Try again.',
      enterUrl: 'Enter URL to image',
      processing: 'Processing',
      bytes: 'bytes',
      clickSelect: 'Click to select file or drop a file here. Accepted file formats:',
      clickSelectUrl: 'Click to add image URL',
      deleteConfirm: 'Are you sure you want to delete this?',
      preview: 'Preview',
      clear: 'Clear'
    },
    datePicker: {
      month: 'Month',
      day: 'Day',
      year: 'Year'
    },
    fieldHeight: {
      feet: 'Feet',
      inch: 'Inches'
    },
    insuranceProviderId: {
      idImage: 'Humana Id Card',
      medicareId: 'Medicare Advantage Identification Number',
      reenter: 'Re-enter',
      notMember: 'I am not currently a Humana Medicare Advantage member.'
    },
    password: {
      password: 'Create Password',
      confirm: 'Confirm',
      validating: 'Validating',
      ok: 'Ok'
    },
    progressBar: {
      estimation: 'On average, it takes about {{time}} to complete.',
      minutes_one: '{{count}} minute',
      minutes_other: '{{count}} minutes',
      percentage: '<span id="t-progressValue">{{progress}}%</span> of the sign-up process has been completed.'
    },
    rangeSlider: {
      maxMinEntryError: 'Ensure maximum length of entry is greater than minimum length of entry'
    },
    select: {
      placeholder: 'Select one from the list'
    },
    selectAdvanced: {
      placeholderSingle: 'Select one from the list',
      placeholderMultiple: 'Select at least one from the list'
    },
    error: {
      errorOccurred: 'An error occurred',
      somethingWrong: '<p>Hi there, it looks like something went wrong on our end. Refresh your page to try again.</p><p>If the problem persists, contact support.</p>',
      defaultError: 'Something went wrong on our end. Try refreshing the page. If the problem persists contact support.',
      contributionDoesNotExist: 'This contribution does not exist',
      contributionDoesNotExistMessage: 'It looks like the contribution you selected is no longer available.'
    }
  },
  fields: {
    age: {
      standardLabel: 'What is your current age (in years)?'
    },
    dob: {
      yearOfBirth: 'What is your year of birth?',
      monthYearBirth: 'What is your month and year of birth?',
      dateOfBirth: 'What is your date of birth?'
    },
    emailConfirm: {
      enterEmail: 'Enter an email address',
      reEnterEmail: 'Re-enter that same email address'
    },
    english: {
      label: 'Are you able to read, speak, and understand English?'
    },
    ethnicity: {
      label: 'Which racial or ethnic group(s) do you identify with? Select all that apply.',
      americanOrAlaskan: 'American Indian or Alaskan Native',
      asian: 'Asian',
      blackOrAfrican: 'Black or African American',
      hispOrLat: 'Hispanic, Latino, or Spanish origin',
      eastOrNorthAfrican: 'Middle Eastern or North African',
      hawaiianOrIslander: 'Native Hawaiian or Other Pacific Islander',
      notHispOrLat: 'Not Hispanic or Latino',
      white: 'White',
      anotherRace: 'Another race or ethnicity'
    },
    gender: {
      label: 'What is your current gender? Select all that apply.'
    },
    heightInternational: {
      enterHeight: 'Enter height',
      enterHeightCentimeters: 'Enter height in centimeters',
      enterHeightImperial: 'Enter height in feet and inches',
      enterHeightFeet: 'Enter height in feet',
      enterHeightInches: 'Enter height in inches',
      imperial: 'Imperial',
      metric: 'Metric',
      centimeters: 'Centimeters'
    },
    weightInternational: {
      imperialWeight: 'Enter weight in pounds',
      stoneWeight: 'Enter weight in stones',
      metricWeight: 'Enter weight in kilograms',
      pounds: 'Pounds',
      stones: 'Stones',
      kilograms: 'Kilograms',
      errorMsg: 'Enter a value between {{min}} {{unit}} and {{max}} {{unit}}.',
      errorEmptyPounds: 'Enter whole numbers for stones and pounds (e.g., 12 stones and 11 pounds). If your weight is an exact number of stones with no pounds, enter ‘0’ in the pounds field.',
      errorMsgKgMinMax: 'You must give us your weight without shoes/heavy clothing in kilograms between {{min_kg}} and {{max_kg}} kg.',
      errorMsgKgMin: 'You must give us your weight without shoes/heavy clothing in kilograms greater than or equal to {{min_kg}} kg.',
      errorMsgKgMax: 'You must give us your weight without shoes/heavy clothing in kilograms less than or equal to {{max_kg}} kg.',
      errorMsgOnlyStonesMinMax: 'You must give us your weight without shoes/heavy clothing in pounds, using only whole numbers between {{min_stones}} and {{max_stones}} stones.',
      errorMsgPoundsMinMax: 'You must give us your weight without shoes/heavy clothing in pounds, using only whole numbers between {{min_pounds}} and {{max_pounds}} pounds.',
      errorMsgPoundsMin: 'You must give us your weight without shoes/heavy clothing in pounds, using only whole numbers greater than or equal to {{min_pounds}} pounds.',
      errorMsgPoundsMax: 'You must give us your weight without shoes/heavy clothing in pounds, using only whole numbers less than or equal to {{max_pounds}} pounds.',
      errorMsgStonesMinMax: 'You must give us your weight without shoes/heavy clothing in stones, using only whole numbers between {{min_stones}} and {{max_stones}} stones and {{min_pounds}} and {{max_pounds}} pounds. If your weight is an exact number of stones and no pounds, enter \'0\' in the pounds field.',
      errorMsgStonesPoundsMin: 'You must give us your weight without shoes/heavy clothing in stones, using only whole numbers greater or equal to {{min_stones}} stones and {{min_pounds}} pounds, If your weight is an exact number of stones and no pounds, enter \'0\' in the pounds field.',
      errorMsgStonesPoundsMax: 'You must give us your weight without shoes/heavy clothing in stones, using only whole numbers less than or equal to {{max_stones}} stones and {{max_pounds}} pounds, If your weight is an exact number of stones and no pounds, enter \'0\' in the pounds field.',
      errorMsgDecimalsStonesPounds: 'Enter whole numbers for stones and pounds (e.g., 12 stones and 11 pounds).',
      errorMsgDecimalsPounds: 'Enter whole numbers for pounds (e.g., 199 pounds).',
      errorMsgKgMinMaxDecimalsAllowed: 'Enter kilograms with 1 decimal point (e.g., 90.0 kilograms).',
      errorMsgKgMinMaxAndDecimals: 'You must give us your weight without shoes/heavy clothing in kilograms between {{min_kg}} and {{max_kg}} kg, with 1 decimal place.',
      errorMsgKgMinAndDecimals: 'You must give us your weight without shoes/heavy clothing in kilograms greater than or equal to {{min_kg}} kg, with 1 decimal place.',
      errorMsgKgMaxAndDecimals: 'You must give us your weight without shoes/heavy clothing in kilograms less than or equal to {{max_kg}} kg, with 1 decimal place.'
    },
    languageIntl: {
      label: 'Are you able to read, speak, and understand {{language}}?'
    },
    fileUpload: {
      uploadingFiles: 'Uploading files',
      numberFiles_one: '{{count}} file',
      numberFiles_other: '{{count}} files',
      formatFileNumError: 'Number of files selected exceeds the maximum allowed. The first {{number}} of the selected files have been added.',
      fileSizeError: 'Confirm that each file is less than {{size}} and in JPEG or PNG format.',
      selectDropFile: 'Click to select file or drop up to {{maxFileCount}}  here.',
      formatAccepted: 'Accepted file formats: {{formatAccept}}',
      uploadAFile: 'Upload at least one file.'
    },
    sex: {
      label: 'What sex were you assigned at birth (for example, what was initially listed on your birth certificate)?'
    },
    countryResident: {
      questionLabel: 'Do you currently live in {{country}}?',
      US: 'the US',
      UK: 'the UK',
      Germany: 'Germany',
      France: 'France',
      Canada: 'Canada',
      Italy: 'Italy',
      Spain: 'Spain'
    },
    usResident: {
      questionLabel: 'Do you currently live in the United States?'
    },
    pregnancy: {
      questionLabel: 'Are you currently pregnant?',
      currentlyPregnant: 'Yes, I am currently pregnant',
      planningToBePregnant: 'Yes, I am planning to become pregnant in the next year',
      dontKnow: 'I don’t know'
    },
    ranking: {
      required: 'Rank at least 1 option',
      requiredMinOptions: 'Rank at least {{min}} options.',
      shouldRankAllOptions: 'Rank all the options.'
    }
  },
  validations: {
    required: 'Enter a response.',
    requiredCheckbox: 'You must select at least one.',
    greaterThanZero: 'Enter value greater than 0.',
    invalidEmail: 'Your email address does not appear to be valid',
    alphaNumericError: 'This field can only contain letters and numbers.',
    lengthError: {
      max: 'This field must contain no more than {{max}} characters (current length {{length}})',
      min: 'This field must contain at least {{min}} characters (current length {{length}})'
    },
    textOnly: 'This field can only contain letters',
    phoneError: 'This field must be in the format of 12345678999 or 1 (234) 567-8999',
    dateValidation: 'Select a day, month and year.',
    timeValidation: 'Use the format 11:11 AM',
    zipValidation: 'US zipcode must contain 5 digits.',
    invalidJson: 'File has invalid JSON format',
    invalidFileType: '{{fileName}} is an invalid file type. The accepted file format is: {{acceptedTypes}}',
    invalidFileTypes: '{{fileName}} is an invalid file type. The accepted file formats are: {{acceptedTypes}}',
    noEmptyArray: 'Files not uploaded due to number of files. Upload up to {{maxFiles}} photos and try again.',
    maxFilesUpload: 'You have selected too many files.',
    maxFileSize: 'Files {{fileList}} exceeds the allowed file size.',
    humanaLengthValidation: 'Your Humana ID must be an H plus {{length}} numbers.',
    mustCheck: 'This must be checked to proceed.',
    bpValid: 'Enter a valid blood pressure measurement',
    pleaseConfirmEmail: 'Confirm you typed your email address correctly',
    datetime: {
      selectDateTime: 'Select a date and time',
      validDate: 'Enter a valid date',
      validTime: 'Enter a valid time',
      validDateRange: 'Enter a date in the range  {{startDate}} - {{endDate}}',
      validAfterDate: 'Enter a date after {{validDate}}',
      validBeforeDate: 'Enter a date before {{validDate}}',
      validTimeRange: 'Enter a time in the range {{timeRange}}'
    },
    dob: {
      invalidDate: 'Invalid Date',
      invalidDateFormat: 'Invalid date format, expects {{format}}',
      cannotBeFuture: 'Date cannot be in the future.'
    },
    number: {
      minMax: 'Enter a number greater than or equal to {{min}} and less than or equal to {{max}}.',
      min: 'Respond with a number greater than or equal to {{min}}',
      max: 'Respond with a number less than or equal to {{max}}',
      decimal: 'Respond with a number containing {{decimal}} decimal places (Ex.: X{{zeros}})'
    },
    heightInternational: {
      customImperialMinMax: 'You must give us your height without shoes in feet and inches, using only numbers between {{min}} and {{max}} feet and between {{minInches}} and {{maxInches}} inches. If your height is an exact number of feet and no inches, enter ‘0’ in the inches field.',
      customImperialMin: 'You must give us your height without shoes in feet and inches, using only numbers greater than or equal to {{min}} feet and between {{minInches}} and {{maxInches}} inches. If your height is an exact number of feet and no inches, enter ‘0’ in the inches field.',
      customImperialMax: 'You must give us your height without shoes in feet and inches, using only numbers less than or equal to {{max}} feet and between {{minInches}} and {{maxInches}} inches. If your height is an exact number of feet and no inches, enter ‘0’ in the inches field.',
      customImperialMinMaxFeetBoundsEmptyFeet: 'Enter whole numbers for feet.',
      customImperialMinMaxFeetBounds: 'You must give us your height, in feet, without wearing shoes, using only numbers between {{min}} and {{max}}.',
      customImperialMinMaxInchesBounds: 'You must give us your height, in inches, without wearing shoes, using only numbers between {{minInches}} and {{maxInches}}.',
      customImperialFeetDecimalPlaces: 'Enter whole numbers for feet and inches (e.g., 5 feet and 6 inches).',
      customImperialInchesDecimalPlaces: 'Enter whole numbers for feet and inches (e.g., 5 feet and 6 inches).',
      customImperialInchesEmpty: 'Enter whole numbers for feet and inches. (e.g., 5 feet and 6 inches). If your height is an exact number of feet with no inches, enter ‘0’ in the inches field.',
      customMetricMinMax: 'You must give us your height without shoes in centimeters, using only numbers between {{min}} and {{max}} centimeters.',
      customMetricMin: 'You must give us your height without shoes in centimeters, using only numbers greater than or equal to {{min}} centimeters.',
      customMetricMax: 'You must give us your height without shoes in centimeters, using only numbers less than or equal to {{max}} centimeters.',
      customMetricDecimalPlaces: 'Enter whole numbers for centimeters.'
    },
    password: {
      length: 'At least 12 characters in length',
      lowercase: 'At least 1 lowercase character',
      number: 'At least 1 number',
      specialCharacter: 'At least 1 special character',
      uppercase: 'At least 1 uppercase character'
    },
    checkboxWithNone: {
      minMaxOptions: 'Select between {{min}} and {{max}} options.'
    },
    date: {
      minMax: 'Select a date between {{min}} and {{max}}'
    }
  },
  routes: {
    contribution: {
      declineLabel: 'I Decline to Sign this Document',
      areYouSureExit: 'Are you sure you want to exit the survey?',
      answersWontBeSaved: "Your answers on this page won't be saved if you leave."
    },
    ehr: {
      components: {
        introPage: {
          secureDataMessage: 'Your electronic health data is secure. To learn more click ',
          introTipTitle: 'You will need: '
        },
        findPage: {
          connectButtonText: 'Connect'
        }
      }
    },
    followUp: {
      components: {
        complete: {
          title: 'Thank you for your Contribution!',
          dashboardMessage: "Visit your Dashboard if you want to see what's next"
        },
        error: {
          title: 'There was a conflict with your submission.',
          content: 'Sorry, it looks like the survey page you are trying to submit was already submitted. Continue to your',
          dashboardLink: 'project dashboard',
          contentExtra: 'where you will be able to continue where you left off.',
          followUpTitle: 'This survey is no longer available.',
          followUpContent: 'Check your email inbox to ensure you are completing the survey with today\'s date. If you believe that you are seeing this message this in error, use the "Contact Us" link above for  help.'
        }
      }
    },
    logIn: {
      heading: 'Log in to {{study_name}}',
      content: 'Log in using the username and password you created during sign-up.',
      description: 'Don’t have an account? ',
      signUp: 'Create account',
      noProvider: 'No identity providers configured. If the problem persists, contact support.'
    },
    signUp: {
      codeReSent: 'We have sent a new confirmation code.',
      heading: 'Get started',
      content: 'We just need to collect some info to create your account.',
      description: 'Already have an account? ',
      confirmation_heading: 'Confirm Email',
      confirmation_text: 'We’ve sent you a verification code to the email address you entered. Enter it below to verify your email address.',
      login: 'Login',
      form: {
        phoneNumber: 'Phone Number',
        email: 'Email Address',
        password: 'Password',
        confirm: 'Confirm Password',
        code: 'Code',
        code_submit: 'Continue',
        confirmation_resend_text: "Didn't receive it?",
        confirmation_resend_link: 'Resend Code',
        submit: 'Create account'
      },
      errors: {
        UsernameExistsException: 'Unable to create your account. Try again, or if you think you may have previously registered, consider resetting your password from the login page.'
      }
    },
    forgotPassword: {
      codeSent: 'We have sent a confirmation code.',
      heading: 'Did you for forgot your password?',
      willSendACode: 'We will send a verification code to your email address.',
      sendCode: 'Send Verification Code',
      success: 'You have successfully updated your password',
      "return": 'Return to login',
      resend: 'Resend code',
      reset: 'Reset Password',
      codeLabel: 'Code',
      passwordLabel: 'New Password',
      confirmPasswordLabel: 'Confirm your new password',
      instructions: 'Enter your confirmation code and your new password',
      emailLabel: 'Email Address',
      errorMessage: 'Something went wrong. Request a code again',
      required: 'This field is required'
    },
    oneClickContribution: {
      components: {
        error: {
          header: 'Wait!! You may not be done!',
          contactMessage: 'If you believe you have reached this message in error, contact us for assistance.',
          expiredMessage: 'You have clicked on an expired link.',
          internalErrorMessage: 'An error has occurred.',
          changedResponseHeader: 'Thank you for responding!',
          changedResponseMessage: 'We have already recorded your earlier response and can only record one response per survey.\n\nIf you would like to submit a different response, contact our Support Team by clicking "Contact Us".'
        }
      }
    },
    screener: {
      someResponsesInvalid: 'Looks like some responses are missing or are in an unacceptable format.',
      goToFirstQuestion: 'Go to first question.'
    },
    verifyIdentity: {
      components: {
        failurePage: {
          title: 'Unfortunately, we are not able to verify the infomation you entered',
          confirmText: 'Reach out to our <strong>Support team</strong> with additional questions.',
          contactButton: 'Contact Us',
          suspendedButton: 'Go to Settings',
          errorMessage: 'Something went wrong with your request. Try again later.'
        },
        introPage: {
          title: 'ID Verification',
          intro: '<p>In this step, we will verify your entries and may send you up to two text messages (additional charges from your phone service provider may apply).</p><p>When you are ready, click <strong>Continue</strong> below. If you have any questions, reach out to our <strong>Support team</strong>.</p>',
          buttonContinue: 'Continue',
          buttonContact: 'Contact Us'
        },
        otpPage: {
          title: 'Two-Step Verification',
          defaultMessage: 'We can not verify your code. Try again by clicking Resend below.',
          body: {
            enterCode: 'If you did not receive the passcode, click',
            resend: 'Resend',
            below: 'below.'
          },
          buttonContinue: 'Continue',
          buttonResend: 'Resend',
          placeholder: 'Enter the passcode'
        },
        successPage: {
          title: 'Congratulations!',
          confirmText: 'We have successfully verified your entries. Click the button below to continue the project.',
          errorMessage: 'Something went wrong with your request. Try again later.'
        }
      }
    },
    informedConsent: {
      progressLabel: 'You have completed {{percentage}}% of the informed consent.'
    },
    enrollment: {
      verifyEmail: {
        title: 'Verify Your Email Address',
        emailSent: 'We’ve just sent an email to the address you provided. If you did not receive it,',
        clickHere: 'click here to re-send.',
        emailSentRecently: 'An email was sent recently. Wait for a few minutes and try again.'
      },
      verifyPhone: {
        title: 'Phone Number Verification',
        confirmText: 'We sent a code to',
        placeHolder: 'Enter verification code',
        messageSent: "Didn't get it? ",
        resend: 'Resend',
        verifyOtp: 'Verify',
        otpInvalid: 'OTP Code invalid. Try again.',
        otpValid: 'Your phone number has been changed.',
        havingIssues: "If you're having any issues, you can always contact us for help.",
        contactUs: 'Contact Us'
      }
    },
    settings: {
      tabs: {
        accountSettings: 'Account Settings',
        studyDocuments: 'Participant Documents',
        security: 'Security',
        contactInfo: 'Contact Info'
      },
      dashboardWithdrawal: {
        dashboardWithdrawalLabel: 'Withdraw from Project',
        dashboardWithdrawalLink: 'Withdrawal',
        dashboardWithdrawalModalParagragh1: 'By withdrawing, you will no longer be able to participate in this project. Your previously collected project data may continue to be used and shared for the purposes stated in your informed consent document. You will not be eligible for compensation and you will not be able to re-join this project.',
        dashboardWithdrawalModalReason: 'Select the most relevant reason for your withdrawal.',
        dashboardWithdrawalModalParagragh2: "If you're having any issues, you can always contact us for help instead of permanently removing yourself from this project!"
      },
      changeEmail: {
        enterYourEmail: 'Enter your new email address.',
        confirmChange: 'Confirm Change',
        verificationEmailSent: 'A verification email was sent to your new email address. Check your inbox to verify your email and complete the update.',
        errorMessage: 'There was a problem updating your email. Contact us for further assistance.',
        defaultMessage: "If you're having any issues, you can always contact us for help.",
        securityWarning: "For security reasons, contact us directly if you'd like to change your email address during the project.",
        securityMessage: "We'd be happy to update that information for you.",
        changeEmailAddress: 'Change',
        changeEmailAddressHead: 'Email Address',
        changeEmailAddressTitle: 'Change your email address',
        accountSettings: 'Account Settings',
        accountInformation: 'Account Information',
        editYourInformation: 'Edit your email address, password, and other account information.'
      },
      changePhoneNumber: {
        changePhoneNumber: 'Change',
        changePhoneNumberHead: 'Phone Number',
        changePhoneNumberTitle: 'Change your phone number',
        enterPhoneNumber: 'Enter your new phone number.',
        confirmChange: 'Confirm change',
        havingIssues: "If you're having any issues, you can always contact us for help.",
        contactUs: 'Contact Us',
        defaultErrorMessage: 'There was a problem updating your phone number. Contact support.',
        defaultSuccessMessage: 'Your phone number has been changed.'
      },
      changePassword: {
        confirmOldPassword: 'Confirm your old password, and create your new password.',
        noteImpactStudies: 'Note: this will impact any other studies you are enrolled in and your membership on {{company_name}} App.',
        confirmChange: 'Confirm Change',
        issuesMessage: "If you're having any issues, you can always contact us for help.",
        choosePassword: 'Choose a new password.',
        passwordChangeSuccessfully: 'Your password was changed successfully.',
        passwordChangeError: 'There was a problem changing your password. Contact support.',
        changePassword: 'Change',
        changePasswordHead: 'Password',
        changePasswordTitle: 'Change your password',
        oldPasswordPlaceholder: 'Enter current password',
        newPasswordPlaceholder: 'Enter new password',
        confirmNewPasswordPlaceholder: 'Confirm new password',
        oldPassword: 'Old Password',
        newPassword: 'New Password'
      },
      security: {
        title: 'Security',
        subtitle: 'Where you’re signed in',
        text: 'You’re currently signed in to your account on these devices.',
        browser: 'Browser',
        logOut: 'Log Out',
        thisDevice: 'This Device',
        unknownDevice: 'Unknown Device',
        onToggle: 'ON',
        offToggle: 'OFF',
        onToggleTitle: 'Enable auto-logout',
        offToggleTitle: 'Disable auto-logout'
      },
      contactInfo: {
        blurb: '<p>The following questions will ask you to provide some basic personal information to us, including contact information.</p><p>This provided information will be used to contact and update you throughout your participation in the project.</p>',
        edit: 'Edit',
        submit: 'Submit',
        cancel: 'Cancel',
        participantInformation: 'Participant Information',
        genericError: 'Something when wrong. Try again later.',
        updateError: 'Something went wrong while updating your contact information. Try again later.',
        contactInformation: 'Contact Information',
        optionalTitle: 'Optional Fields Below'
      }
    },
    dashboard: {
      defaultHeader: 'Project Dashboard',
      defaultDisplayGroup: 'My Activities',
      completedDisplayGroup: 'Completed Activities',
      noContributionsAvailable: 'No contributions are currently available for this project.',
      confirm: 'Confirm',
      cancel: 'Cancel',
      contributionModal: {
        header: 'Contribution'
      },
      confirmModal: {
        pleaseConfirm: 'Confirm',
        stillAvailableForPreviousDay: 'This is still available, but it’s for a previous day.',
        areYouSure: 'Are you sure you want to do this one?'
      },
      asyncActionDefaultErrorMessage: 'Something went wrong loading this contribution. Try again later.',
      thankYou: {
        completeDailySurvey: 'Thank you for completing your daily survey and contributing to the COVID-19 Experiences Study.',
        contribution: 'Your contribution is a meaningful step towards understanding COVID-19 and its effects in the real world. Feel free to',
        contactUs: 'contact us',
        questions: 'if you have any questions.',
        information: 'For more information regarding COVID-19, refer to the Center for Disease Control and Prevention:',
        seeYou: "See you during tomorrow's daily survey!"
      },
      progressTracker: {
        completed: 'Your Completed Activities',
        remaining: 'Remaining Activities'
      }
    }
  }
};