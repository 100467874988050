import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { format, parseISO } from 'date-fns';
import { logError } from 'src/utils/errorLogger';

import PublishModal from './modals/PublishModal';
import FriendlyReminderModal from './modals/FriendlyReminderModal';

import {
  addPublishErrors,
  clearPublishErrors,
} from '../../../actions/publish_errors_actions';
import { setPublishing } from '../../../actions/publish_actions';
import { loadGraph } from '../../../actions/combination';
import { apiGraph } from '../../../api';
import { checkAsyncOperations } from '../../../api/operations';
import { refreshStudies } from '../../../actions/user_actions';

const timeOut = 15000;

const commonError = (error) => {
  if (_.has(error.response, 'data.errors')) {
    _.forEach(error.response.data.errors, (error) =>
      toastr.error(error, { timeOut }),
    );
  } else if (error.response.status === 403) {
    toastr.error('You are not permitted to publish this study.', { timeOut });
  } else {
    toastr.error('There was a problem publishing this study.', { timeOut });
  }
};

export default (WrappedComponent) => {
  class Comp extends Component {
    constructor(props) {
      super(props);

      this.state = {
        data: null,
        showPublishModal: false,
        showReminderModal: false,
        waitingPublish: false,
      };
    }

    checker; // interval ID
    invalidateQueryFunction;

    checkPublishStatus = ({ jobId, title, setPublishing }) => {
      checkAsyncOperations(jobId)
        .then(({ data }) => {
          const {
            status,
            metadata: { exception_message: exceptionMessage },
          } = data;

          if (status === 'failed') {
            const message = exceptionMessage || 'Failed to publish study';
            setPublishing(false);
            clearInterval(this.checker);
            toastr.error(message, { timeOut });
          } else if (status === 'complete') {
            this.props.refreshStudiesList();
            this.setState({ waitingPublish: false });
            setPublishing(false);
            clearInterval(this.checker);
            toastr.success(`Rejoice! You have published your study ${title}`);
          }
        })
        .catch((e) => {
          clearInterval(this.checker);
          this.error(e);
        });
    };

    componentDidUpdate(prevProps, prevState) {
      const {
        loadGraph,
        graph: { id },
      } = this.props;
      if (
        prevState.waitingPublish === true &&
        this.state.waitingPublish === false
      ) {
        if (this.invalidateQueryFunction) {
          this.invalidateQueryFunction();
        }
        loadGraph(id);
      }
    }

    publishGraph = (publishData, invalidateQuery) => {
      const {
        clearPublishErrors,
        setPublishing,
        loadGraph,
        graph: {
          id,
          content: { title },
        },
        tiles,
      } = this.props;
      const { showReminderModal, data: stateData } = this.state;

      const hasUncofiguredTiles = Object.values(tiles).some(
        ({ content }) => content?.initial_state,
      );

      if (hasUncofiguredTiles && !showReminderModal) {
        return this.setState({
          data: publishData,
          showPublishModal: false,
          showReminderModal: true,
        });
      }

      const data = {
        // Setting this logical operator that returns the function data param (publishData) if state data (stateData) is null or undefined
        ...(stateData ?? publishData),
        application_version_info: {
          study_manager_branch_name: process.env.REACT_APP_STUDY_MANAGER_BRANCH,
          study_manager_branch_tag: process.env.REACT_APP_STUDY_MANAGER_TAG,
          study_manager_commit_sha1:
            process.env.REACT_APP_STUDY_MANAGER_REVISION,
          study_manager_deployed_at:
            process.env.REACT_APP_STUDY_MANAGER_RELEASE_DATE,
          circleci_job_url: process.env.REACT_APP_CIRCLE_BUILD_URL,
        },
      };

      apiGraph
        .publish(id, data)
        .then(({ data }) => {
          const { job_id: jobId } = data;
          this.setState({ waitingPublish: true });
          loadGraph(id);
          clearPublishErrors();
          setPublishing(true);
          this.invalidateQueryFunction = invalidateQuery;
          this.checker = setInterval(() => {
            this.checkPublishStatus({
              jobId,
              title,
              setPublishing,
            });
          }, 2000);
        })
        .catch(this.error);

      this.setState({
        data: null,
        showPublishModal: false,
        showReminderModal: false,
      });
    };

    onRestore = async () => {
      const {
        clearPublishErrors,
        loadGraph,
        graph: { id },
      } = this.props;

      try {
        const { data } = await apiGraph.getPublishes(id);
        if (_.isEmpty(data)) return toastr.error('study not published');
        const { id: publishId, created_at } = data[0];

        toastr.confirm(
          `Are you sure you want to revert to version published on ${format(
            parseISO(created_at),
            "PPPP 'at' p",
          )}? Any unpublished changes will be lost.`,
          {
            onOk: () => {
              apiGraph
                .restoreGraph(id, publishId)
                .then((r) => {
                  loadGraph(id);
                  clearPublishErrors();
                  toastr.success(`revert success`);
                })
                .catch(this.error);
            },
          },
        );
      } catch (e) {
        this.error(e);
      }
    };

    error = (e) => {
      this.props.addPublishErrors(e.response.data.errors);
      this.props.setPublishing(false);
      commonError(e);
      logError(e);
    };

    render() {
      const { showPublishModal, showReminderModal } = this.state;

      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            onRestore={this.onRestore}
            onPublish={() => this.setState({ showPublishModal: true })}
          />
          {showPublishModal && (
            <PublishModal
              {...this.props}
              onClose={() => this.setState({ showPublishModal: false })}
              onConfirm={this.publishGraph}
            />
          )}
          {showReminderModal && (
            <FriendlyReminderModal
              {...this.props}
              onClose={() => this.setState({ showReminderModal: false })}
              onConfirm={this.publishGraph}
            />
          )}
        </React.Fragment>
      );
    }
  }

  return connect(
    ({ graph, tiles }) => ({ graph, tiles }),
    (dispatch) => ({
      addPublishErrors,
      clearPublishErrors,
      loadGraph,
      setPublishing,
      refreshStudiesList: () => refreshStudies(dispatch),
    }),
  )(Comp);
};
