import { AuthActions } from '../../../constants';

export const generateMenuOptions = ({ history: { push } }, studyPath) => [
  {
    id: 'overview',
    title: 'Overview',
    icon: 'sequence-nav-icon',
    activeCheck: [
      'overview',
      'participants',
      'permissions',
      'visit-portal',
      'event_delays',
      'tests',
    ],
    onClick: () => push(`${studyPath}/overview`),
  },
  {
    id: 'slate',
    title: 'Slate',
    icon: 'timeline-nav-icon',
    activeCheck: ['edit/slate'],
    onClick: () => push(`${studyPath}/edit/slate`),
    requiredActions: [AuthActions.sequences.show],
  },
  {
    id: 'outline',
    title: 'Outline',
    icon: 'outline-icon',
    activeCheck: ['outline'],
    onClick: () => push(`${studyPath}/outline`),
    requiredActions: [AuthActions.sequences.show],
  },
  {
    id: 'review',
    title: 'Review',
    icon: 'review-nav-icon',
    activeCheck: [
      'review/disqualifications',
      'review/delays',
      'review/emitted_events',
    ],
    onClick: () => push(`${studyPath}/review/disqualifications`),
    requiredActions: [AuthActions.graphs.update],
  },
  {
    id: 'compensation',
    title: 'Comp',
    icon: 'comp-nav-icon',
    activeCheck: ['edit/compensation'],
    onClick: () => push(`${studyPath}/edit/compensation`),
    requiredActions: [AuthActions.enrollments.compensate],
  },
  {
    id: 'schedules',
    title: 'Schedules',
    icon: 'recurring-nav-icon',
    activeCheck: ['schedules'],
    onClick: () => push(`${studyPath}/schedules`),
    requiredActions: [AuthActions.graphs.update],
  },
  {
    id: 'branding',
    title: 'Branding',
    icon: 'branding-nav-icon',
    activeCheck: ['branding'],
    onClick: () => push(`${studyPath}/branding`),
    requiredActions: [AuthActions.graphs.update],
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: 'settings-nav-icon',
    activeCheck: [
      'settings/participant_support',
      'settings/general',
      'settings/display_participants',
      'settings/event_delays',
      'settings/external',
      'settings/disqualification',
      'settings/email_invitation',
      'settings/webhooks',
      'settings/integrations',
    ],
    onClick: () => push(`${studyPath}/settings/general`),
    requiredActions: [AuthActions.graphs.update],
  },
];

export const displaySecondaryMenu = [
  'overview',
  'participants',
  'permissions',
  'visit-portal',
  'event_delays',
  'tests',
  'upload_review',
  'icf_review',
];

export const doNotDisplaySecondaryMenu = ['settings'];
