import { AuthActions, NavKeys, roles } from '../constants';

import { AuthWrap } from './auth';
import Icon from '@evidation/icon';
import IconLibrary from './icon/IconLibrary';
import { Link } from 'react-router-dom';
import NavIconList from '../assets/NavIconList';
import _ from 'lodash';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useFeatureFlags } from 'src/features/workspaces/hooks/useFeatureFlags';
import useQueryParams from 'src/hooks/useQueryParams';

const defaultColor = '#000000';
const selectedColor = '#1de7a7';

const StyledSecondaryMenu = styled.div`
  grid-area: application-subnav;
  background-color: #fafafa;
  border-bottom: 1px solid #e9e9e9;
  color: #000000;
  display: flex;
  font-family: 'BrandonGrotesque';
  font-size: 0.875rem;
  font-weight: bold;
  min-width: 100%;
  text-align: center;
  text-transform: uppercase;

  > div {
    display: none;
  }

  > a {
    align-items: center;
    color: #000000;
    cursor: pointer;
    display: flex;
    padding: 0 0.5rem;
    margin: 0 0.5rem;
    position: relative;
    text-decoration: none;

    > div {
      margin: 1rem auto;

      &.text {
        margin-left: 0.25rem;
      }
    }

    &.selected-true {
      border-bottom: 3px solid #1de7a7;
      color: #1de7a7;
    }
  }
`;

const topNav = ({
  match: {
    params: { graph_id },
  },
}) => ({
  [NavKeys.overview]: {
    path: `/study/${graph_id}/overview`,
    label: `Status`,
    required: [],
  },
  [NavKeys.participants]: {
    path: `/study/${graph_id}/participants`,
    label: `Participants`,
    required: [roles.participantSupport],
  },
  [NavKeys.upload_review]: {
    path: `/study/${graph_id}/upload_review`,
    label: `Upload Review`,
    required: [AuthActions.files.index],
  },
  [NavKeys.icf_review]: {
    path: `/study/${graph_id}/icf_review`,
    label: `ICF Review`,
    required: [
      AuthActions.informed_consents.index,
      AuthActions.informed_consents.download,
    ],
  },
  [NavKeys.permissions]: {
    path: `/study/${graph_id}/permissions`,
    label: `Permissions`,
    required: [AuthActions.roles.index],
  },
  [NavKeys.event_delays]: {
    path: `/study/${graph_id}/event_delays`,
    label: `Event Delays`,
    required: [AuthActions.studies.event_delays],
  },
});

const renderTitle = (title) => {
  if (!title) {
    return null;
  }

  return <div className="text">{title}</div>;
};

const inArray = (haystack, needle) => new RegExp(haystack).test(needle);

const isSelected = (option, currentPath = ``) => {
  return inArray(option, currentPath);
};

const getIconName = (label) => {
  switch (label) {
    case 'Status':
      return 'status-nav-icon';
    case 'Event Delays':
      return 'event-delay-tile-icon';
    case 'Participants':
      return 'participants-nav-icon';
    case 'Permissions':
      return 'permissions-nav-icon';
    case 'Visit Portal':
      return 'visit-portal-nav-icon';
    default:
      return 'table-nav-icon';
  }
};

const SecondaryMenu = ({ location, ...props }) => {
  const menuOptions = topNav(props);
  const { features } = useFeatureFlags();

  const queryParams = useQueryParams();
  const isEditMode = queryParams.get('major_version');

  return (
    <StyledSecondaryMenu>
      <IconLibrary list={NavIconList}>
        {_.map(menuOptions, (menuOption) => {
          const isActive = isSelected(menuOption.path, location.pathname);
          const overviewSnakedName = `overview_${_.snakeCase(
            menuOption.label,
          )}`;
          const isInFeatureFlagList = features.some(
            (feature) => feature.name === overviewSnakedName,
          );

          if (!isInFeatureFlagList) return null;
          if (isEditMode && menuOption.label === 'Permissions') return null;

          return (
            <AuthWrap
              key={`study-menu-${menuOption.path}`}
              requiredActions={menuOption['required']}
            >
              <Link
                to={menuOption['path']}
                id={`t-navLink_${_.snakeCase(menuOption.label)}`}
                className={`selected-${isActive}`}
              >
                <Icon
                  name={getIconName(menuOption.label)}
                  color={isActive ? selectedColor : defaultColor}
                  size="24px"
                />

                {renderTitle(menuOption.label)}
              </Link>
            </AuthWrap>
          );
        })}
      </IconLibrary>
    </StyledSecondaryMenu>
  );
};

SecondaryMenu.defaultProps = {
  option: { activeCheck: [] },
};

SecondaryMenu.propTypes = {
  location: object.isRequired,
  match: object.isRequired,
};

export default SecondaryMenu;
