import axios from 'axios';
import { triageV3 } from './triage';
import { BrandMeta } from './meta';

export type User = {
  cognito_sub?: string;
  email: string;
  email_verified?: boolean;
  is_tenant_owner: boolean;
  family_name: string;
  given_name: string;
};

export type TenantURL = {
  upload_url: string;
  expires_at: string;
  public_url: string;
};
export type Attribute = {
  user_pool_attribute: string;
  saml_attribute: string;
};
export type BaseProviderData = {
  provider_type: string;
  provider_name: string;
};

export type RawCommonProperties = {
  IDPInit: boolean;
  IDPSignout: boolean;
  EncryptedResponses: boolean;
  RequestSigningAlgorithm: string;
};
export type RawSAMLProvider = {
  provider_details: {
    MetadataURL: string;
  } & RawCommonProperties;
  attribute_mapping: { [key: string]: string };
} & BaseProviderData;
export type RawOIDCProvider = {
  provider_details: {
    authorize_scopes: string;
    client_id: string;
    client_secret: string;
    attributes_request_method: string;
    attributes_url?: string;
    authorize_url?: string;
    jwks_uri?: string;
    issuer_url?: string;
    token_url?: string;
    oidc_issuer?: string;
  };
  attribute_mapping: { [key: string]: string };
} & BaseProviderData;
export type SAMLProviderData = {
  endpoint_url: string;
  attribute_mapping: Attribute[];
} & BaseProviderData;
export type OIDCProviderData = {
  client_id: string;
  client_secret: string;
  authorized_scopes: string;
  attributes_request_method: string;
  attributes_url?: string;
  authorize_url?: string;
  jwks_uri?: string;
  token_url?: string;
  issuer_url?: string;
  oidc_issuer?: string;
  attribute_mapping: Attribute[];
} & BaseProviderData;
export type FeatureFlagCategory = 'component' | 'feature' | 'tile';
export type FeatureFlag = {
  name: string;
  category: FeatureFlagCategory;
  description: string;
  status: boolean;
};
export type VersionHistoryFields = {
  application: string;
  version: string;
  deployed_at: string;
};

export const WorkspacesService = {
  getAll: () => {
    return triageV3.get<User[]>('admin/users').then(({ data }) => data);
  },
  create: (user: User) => {
    return triageV3.post<User>(`admin/users/`, user).then(({ data }) => data);
  },
  update: ({ userId, user }: { userId: string; user: Partial<User> }) => {
    return triageV3
      .put<User>(`admin/users/${userId}`, user)
      .then(({ data }) => data);
  },
  delete: (userId: string) => {
    return triageV3
      .delete<User>(`admin/users/${userId}`)
      .then(({ data }) => data);
  },
  updateTenantSettings: (data: any) => {
    return triageV3
      .put<BrandMeta>('tenant/settings', data)
      .then(({ data }) => data);
  },
  getTenantImageUrl: () => {
    return triageV3
      .get<TenantURL>('tenant/asset_upload_url')
      .then(({ data }) => data);
  },
  uploadImage: (url: string, image: Buffer) => {
    return axios.put(url, image);
  },
  createAdminsProvider: (provider: RawSAMLProvider | RawOIDCProvider) => {
    return triageV3
      .post<RawSAMLProvider | RawOIDCProvider>(
        `tenant/settings/administrators/identity_providers
      `,
        provider,
      )
      .then(({ data }) => data);
  },
  updateAdminsProvider: (provider: RawSAMLProvider | RawOIDCProvider) => {
    return triageV3
      .put<RawSAMLProvider | RawOIDCProvider>(
        `tenant/settings/administrators/identity_providers/${provider.provider_name}
      `,
        provider,
      )
      .then(({ data }) => data);
  },
  deleteAdminsProvider: (name: string) => {
    return triageV3
      .delete<RawSAMLProvider | RawOIDCProvider>(
        `tenant/settings/administrators/identity_providers/${name}`,
      )
      .then(({ data }) => data);
  },
  getAllAdminsProviders: () => {
    return triageV3
      .get<BaseProviderData[]>(
        `tenant/settings/administrators/identity_providers`,
      )
      .then(({ data }) => data);
  },
  getAdminsProvider: (name: string) => {
    return triageV3
      .get<RawSAMLProvider | RawOIDCProvider>(
        `tenant/settings/administrators/identity_providers/${name}`,
      )
      .then(({ data }) => data);
  },
  createParticipantsProvider: (provider: RawSAMLProvider | RawOIDCProvider) => {
    return triageV3
      .post<RawSAMLProvider | RawOIDCProvider>(
        `tenant/settings/participants/identity_providers
      `,
        provider,
      )
      .then(({ data }) => data);
  },
  updateParticipantsProvider: (provider: RawSAMLProvider | RawOIDCProvider) => {
    return triageV3
      .put<RawSAMLProvider | RawOIDCProvider>(
        `tenant/settings/participants/identity_providers/${provider.provider_name}
      `,
        provider,
      )
      .then(({ data }) => data);
  },
  deleteParticipantsProvider: (name: string) => {
    return triageV3
      .delete<RawSAMLProvider | RawOIDCProvider>(
        `tenant/settings/participants/identity_providers/${name}`,
      )
      .then(({ data }) => data);
  },
  getAllParticipantsProviders: () => {
    return triageV3
      .get<BaseProviderData[]>(
        `tenant/settings/participants/identity_providers`,
      )
      .then(({ data }) => data);
  },
  getParticipantsProvider: (name: string) => {
    return triageV3
      .get<RawSAMLProvider | RawOIDCProvider>(
        `tenant/settings/participants/identity_providers/${name}`,
      )
      .then(({ data }) => data);
  },
  /**
   * Feature Flags
   **/
  getFeatureFlags: () => {
    return triageV3
      .get<FeatureFlag[]>(`/tenant/settings/features`)
      .then(({ data }) => data);
  },
  updateFeatureFlags: (featureFlags: FeatureFlag[]) => {
    return triageV3
      .put<FeatureFlag[]>(`/tenant/settings/features`, featureFlags)
      .then(({ data }) => data);
  },
  /**
   * auth_config
   */
  getAuthConfig: () => {
    return triageV3.get<any>(`/manager/auth_config`).then(({ data }) => data);
  },
  /**
   * Version History
   */
  getVersionHistory: () => {
    return triageV3
      .get<VersionHistoryFields[]>(`platform_versions`)
      .then(({ data }) => data);
  },
};
