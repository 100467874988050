var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var HideView = function (props) {
    return (React.createElement("svg", __assign({ width: 20, height: 20, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { d: "M18.402 9.496c-.686-1.446-1.503-2.626-2.45-3.539l-.995.994c.81.775 1.517 1.788 2.127 3.05-1.625 3.363-3.931 4.96-7.084 4.96-.946 0-1.818-.145-2.615-.437L6.31 15.601c1.106.511 2.337.767 3.691.767 3.754 0 6.555-1.955 8.402-5.866a1.178 1.178 0 000-1.006zm-1.241-6.262l-.833-.834a.156.156 0 00-.22 0L13.97 4.536c-1.178-.602-2.501-.903-3.97-.903-3.755 0-6.556 1.955-8.403 5.865a1.178 1.178 0 000 1.006c.738 1.555 1.627 2.801 2.666 3.739l-2.067 2.066a.156.156 0 000 .22l.834.835a.157.157 0 00.22 0l13.91-13.909a.157.157 0 000-.221zM2.916 10C4.543 6.637 6.85 5.04 10 5.04c1.065 0 2.034.182 2.913.554L11.54 6.967a3.438 3.438 0 00-4.651 4.651l-1.63 1.63C4.358 12.45 3.58 11.372 2.916 10zm4.818 0a2.19 2.19 0 012.856-2.083l-2.752 2.751A2.187 2.187 0 017.734 10z", fill: "#1A1A1A" }),
        React.createElement("path", { d: "M9.922 12.188c-.067 0-.134-.003-.2-.01L8.69 13.21a3.44 3.44 0 004.442-4.442L12.101 9.8a2.208 2.208 0 01-.158 1.037 2.185 2.185 0 01-2.02 1.35z", fill: "#1A1A1A" })));
};
