import Icon from '@evidation/icon';
import React from 'react';
import _ from 'lodash';
import { default_experience_key } from '../../../../constants';
import * as C from '../common';

export const validateIdEvent = (
  v,
  { content: { external_clients = [] } = {} },
) => {
  if (v && !_.isEmpty(external_clients)) {
    return undefined;
  } else if (!_.isEmpty(external_clients)) {
    return 'required';
  }
};

export const validateClients = (
  v,
  { content: { external_id_assignment_event } = {} },
) => {
  if (!_.isEmpty(v) && external_id_assignment_event) {
    return undefined;
  } else if (external_id_assignment_event) {
    return 'required';
  }
};

export const validateDisplayableFields = v => {
  if (!_.isEmpty(v) && _.includes(v, 'identifier')) {
    return undefined;
  } else {
    return "'identifier' field required.";
  }
};

export default ({
  roles,
  arms,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  externalIds = [],
}) => {
  const canConfigureDisplayableHeaders =
    _.includes(roles, 'owner') || _.includes(roles, 'participant_support');

  return [
    [
      {
        name: 'content',
        input: [
          {
            label: 'External IDs',
            interface: 'settings_heading',
            name: 'external_ids_settings',
          },
          {
            name: 'external_clients',
            interface: 'array',
            className: 'external_clients',
            required: false,
            addButtonLabel: 'add an external id',
            deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
            validate: [validateClients],
            items: [
              {
                name: 'external_id_format',
                label: 'ID Format',
                className: 'external_id_format',
                interface: 'select',
                inputStyle: 'muted',
                options: [
                  { value: 'default', label: 'Random Hex' },
                  {
                    value: 'prefix_range',
                    label: 'Sequential Prefix Range (AB# to AB#)',
                  },
                ],
                initialValue: { value: 'default', label: 'Random Hex' },
                required: true,
                description: `
                <p>
                  <strong>Formats</strong><br>
                  Default : 32-character alpha numeric<br>
                  Prefix Range (AB# to AB#) : prefix + incrementing numbers within a range<br>
                  Random (######) : 6 random digits<br>
                  Prefix (AB#####) : prefix + followed by N random digits
                </p>`,
              },
              {
                name: 'external_id_prefix',
                label: 'ID Prefix',
                interface: 'textinput',
                condition: (path, state) =>
                  _.includes(
                    ['prefix_range', 'prefix_random'],
                    _.get(
                      state.values,
                      `${path.slice(0, -1).join('.')}.external_id_format`,
                    ),
                  ),
              },
              {
                name: 'external_id_prefix_range_start',
                className: 'external_id_prefix_range_start',
                label: 'Range Start',
                interface: 'numberinput',
                required: true,
                validate: [C.validateNumber, C.validateInt],
                condition: (path, state) =>
                  _.includes(
                    ['prefix_range'],
                    _.get(
                      state.values,
                      `${path.slice(0, -1).join('.')}.external_id_format`,
                    ),
                  ),
              },
              {
                name: 'external_id_prefix_range_end',
                className: 'external_id_prefix_range_end',
                label: 'Range End',
                interface: 'numberinput',
                required: true,
                validate: [
                  C.validateNumber,
                  C.validateInt,
                  (v, state, props, path) => {
                    const range_start = _.get(
                      state,
                      path.replace(
                        'external_id_prefix_range_end',
                        'external_id_prefix_range_start',
                      ),
                    );
                    return parseInt(range_start, 10) > v
                      ? 'Range end must be greater than or equal to range start'
                      : undefined;
                  },
                ],
                condition: (path, state) =>
                  _.includes(
                    ['prefix_range'],
                    _.get(
                      state.values,
                      `${path.slice(0, -1).join('.')}.external_id_format`,
                    ),
                  ),
              },
              {
                name: 'external_id_prefix_n_random_digits',
                label: 'Number of Random Digits',
                interface: 'numberinput',
                required: true,
                validate: [C.validateNumber, C.validateInt],
                condition: (path, state) =>
                  _.includes(
                    ['prefix_random'],
                    _.get(
                      state.values,
                      `${path.slice(0, -1).join('.')}.external_id_format`,
                    ),
                  ),
              },
              {
                name: 'type',
                label: 'Client Type (e.g. shipping_client)',
                interface: 'textinput',
                required: true,
              },
              {
                name: 'name',
                className: 'external_client_name',
                label: 'Client Name (e.g. Evidation)',
                interface: 'textinput',
                required: true,
              },
            ],
          },
          {
            label: 'External ID Assignment Event',
            interface: 'text_horizontal',
            name: 'external_id_assignment_event',
            validate: [validateIdEvent],
          },
          {
            label: 'Vault Transmission Event',
            interface: 'text_horizontal',
            name: 'vault_transmission_trigger',
          },
          {
            label: 'Shipping Report Configurations',
            interface: 'settings_heading',
            name: 'shipping_report_settings',
          },
          {
            name: 'shipping_config',
            interface: 'array',
            addButtonLabel: 'add a shipping report configuration',
            deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
            items: [
              {
                name: 'event_trigger',
                label: 'Event Identifier',
                description:
                  'This is the event that triggers when a participant populates into the shipping report.',
                interface: 'text_horizontal',
                required: true,
              },
              {
                name: 'sheet_title',
                label: 'Sheet Title',
                interface: 'text_horizontal',
                required: true,
              },
              {
                name: 'client',
                label: 'External Id',
                interface: 'select_horizontal',
                options: externalIds,
              },
            ],
          },
          {
            label: 'Displayable Fields',
            description:
              'These are the fields that show up in the shipping report.',
            interface: 'settings_heading',
            name: 'displayable_shipping_headers_settings',
          },
          {
            name: 'displayable_shipping_headers',
            interface: 'flatarray',
            type: 'text_horizontal',
            addButtonLabel: 'add a field',
            validate: [validateDisplayableFields],
            deleteButtonIcon: <Icon name="close-action-icon" size="25px" />,
            disabled: !canConfigureDisplayableHeaders,
          },
        ],
      },
    ],
  ];
};
